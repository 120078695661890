import { createId } from "@paralleldrive/cuid2";
import { index, pgTable, text, timestamp } from "drizzle-orm/pg-core";

import { DBUser } from "./auth";

export const DBApiKey = pgTable(
  "api_keys",
  {
    id: text("id").notNull().primaryKey().$defaultFn(createId),

    userId: text("userId").references(() => DBUser.id, { onDelete: "cascade" }),

    name: text("name").notNull(),
    description: text("description"),
    key: text("key").notNull().unique(),

    permissions: text("permissions").array().notNull().$type<string[]>(),

    lastUsedAt: timestamp("lastUsedAt", { mode: "date" }),
    lastScopeUsed: text("lastScopeUsed"),

    createdAt: timestamp("createdAt", { mode: "date" }).notNull().defaultNow(),
    updatedAt: timestamp("updatedAt", { mode: "date" }),
  },
  (key) => ({
    userIdIdx: index("apikeys_userId_idx").on(key.userId),
    keyIdx: index("apikeys_key_idx").on(key.key),
  })
);
