import { createId } from "@paralleldrive/cuid2";
import {
  boolean,
  index,
  pgEnum,
  pgTable,
  text,
  timestamp,
} from "drizzle-orm/pg-core";

import { DBUser } from "./auth";

export enum CustomerSource {
  Website = "website",
  Admin = "admin",
  Talkshop = "talkshop",
}

export const CustomerSourceEnum = pgEnum("customer_source", [
  CustomerSource.Website,
  CustomerSource.Admin,
  CustomerSource.Talkshop,
]);

export const DBCustomer = pgTable(
  "customers",
  {
    id: text("id").notNull().primaryKey().$defaultFn(createId),
    userId: text("userId").references(() => DBUser.id, { onDelete: "cascade" }),

    email: text("email"),
    phone: text("phone"),
    name: text("name").notNull(),
    note: text("note"),

    source: CustomerSourceEnum("source")
      .notNull()
      .default(CustomerSource.Website),

    hasBillingAddress: boolean("hasBillingAddress").notNull().default(false),
    billingAddress1: text("billing_address1").notNull(),
    billingAddress2: text("billing_address2"),
    billingCity: text("billing_city").notNull(),
    billingState: text("billing_state").notNull(),
    billingZip: text("billing_zip").notNull(),
    billingCountry: text("billing_country").notNull(),
    billingCompany: text("billing_company"),

    hasShippingAddress: boolean("hasShippingAddress").notNull().default(false),
    shippingSameAsBilling: boolean("sameAsBilling").default(true),
    shippingAddress1: text("shipping_address1"),
    shippingAddress2: text("shipping_address2"),
    shippingCity: text("shipping_city"),
    shippingState: text("shipping_state"),
    shippingZip: text("shipping_zip"),
    shippingCountry: text("shipping_country"),
    shippingCompany: text("shipping_company"),

    authorizeNetCustomerId: text("authorize_net_customer_id"),

    // TODO: Add support for other payment methods

    dealer: boolean("dealer").default(false),
    dealerName: text("dealer_name"),

    createdAt: timestamp("createdAt", { mode: "date" }).notNull().defaultNow(),
    updatedAt: timestamp("updatedAt", { mode: "date" }),
  },
  (customer) => ({
    emailIdx: index("customers-email_idx").on(customer.email),
    dealerIdx: index("customers-dealer_idx").on(customer.dealer),
  }),
);
