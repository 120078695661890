import { createId } from "@paralleldrive/cuid2";
import {
  boolean,
  decimal,
  index,
  integer,
  json,
  pgEnum,
  pgTable,
  serial,
  text,
  timestamp,
} from "drizzle-orm/pg-core";

import { DBUser } from "./auth";
import { DBCustomer } from "./customer";

export enum ConsignmentStatus {
  Created = "created",
  ReviewApproved = "review_approved",
  ReviewRejected = "review_rejected",
  AwaitingSignature = "awaiting_signature",
  SignatureReceived = "signature_received",
  PackageReceived = "package_received",
  CorrectionsNeeded = "corrections_needed",
  Processing = "processing",
  AtAuction = "at_auction",
  Sold = "sold",
  Recycled = "recycled",
  Cancelled = "cancelled",
  Returned = "returned",
  PostAuctionProcessing = "post_auction_processing",
  Completed = "completed",
}

export const DBConsignmentStatus = pgEnum("consignment_status", [
  ConsignmentStatus.Created,
  ConsignmentStatus.ReviewApproved,
  ConsignmentStatus.ReviewRejected,
  ConsignmentStatus.AwaitingSignature,
  ConsignmentStatus.SignatureReceived,
  ConsignmentStatus.PackageReceived,
  ConsignmentStatus.CorrectionsNeeded,
  ConsignmentStatus.Processing,
  ConsignmentStatus.AtAuction,
  ConsignmentStatus.Sold,
  ConsignmentStatus.Recycled,
  ConsignmentStatus.Cancelled,
  ConsignmentStatus.Returned,
  ConsignmentStatus.PostAuctionProcessing,
  ConsignmentStatus.Completed,
]);

export enum ConsignmentSource {
  Website = "website",
  Admin = "admin",
}

export const DBConsignmentSource = pgEnum("consignment_source", [
  ConsignmentSource.Website,
  ConsignmentSource.Admin,
]);

export const DBConsignment = pgTable(
  "consignments",
  {
    id: text("id").notNull().primaryKey().$defaultFn(createId),

    consignmentId: serial("invoiceId"),

    salesRepId: text("salesRepId").references(() => DBUser.id, {
      onDelete: "set null",
    }),

    userId: text("userId").references(() => DBUser.id, {
      onDelete: "set null",
    }),

    customerId: text("customerId").references(() => DBCustomer.id, {
      onDelete: "set null",
    }),

    briefDescription: text("briefDescription"),
    details: text("details"),
    verifiedContents: text("verifiedContents"),

    status: DBConsignmentStatus("status")
      .notNull()
      .default(ConsignmentStatus.Created),
    source: DBConsignmentSource("source")
      .notNull()
      .default(ConsignmentSource.Website),

    shippingCarrier: text("shippingCarrier"),
    shippingPackage: text("shippingPackage"),
    shippingService: text("shippingService"),
    shippingWeightUnits: text("shippingWeightUnits"),
    shippingWeightValue: decimal("shippingWeightValue", {
      precision: 10,
      scale: 2,
    }),
    shippingDate: timestamp("shippingDate", { mode: "date" }),
    shippingLabel: text("shippingLabel"),
    shippingId: text("shippingId"),
    shippingTrackingNumber: text("shippingTrackingNumber"),

    reviewRejectionReason: text("reviewRejectionReason"),
    reviewRejectedAt: timestamp("reviewRejectedAt", { mode: "date" }),
    reviewApprovedAt: timestamp("reviewApprovedAt", { mode: "date" }),
    reviewedById: text("reviewedById").references(() => DBUser.id, {
      onDelete: "set null",
    }),

    shippingLabelCreatedAt: timestamp("shippingLabelCreatedAt", {
      mode: "date",
    }),
    shippingLabelCreatedById: text("shippingLabelCreatedById").references(
      () => DBUser.id,
      {
        onDelete: "set null",
      }
    ),

    agreementSentAt: timestamp("aggreementSentAt", { mode: "date" }),
    agreementSentById: text("agreementSentById").references(() => DBUser.id, {
      onDelete: "set null",
    }),

    signatureReceivedAt: timestamp("signatureReceivedAt", { mode: "date" }),
    signatureReceivedById: text("signatureReceivedById").references(
      () => DBUser.id,
      {
        onDelete: "set null",
      }
    ),

    packageReceivedAt: timestamp("packageReceivedAt", { mode: "date" }),
    packageReceivedById: text("packageReceivedById").references(
      () => DBUser.id,
      {
        onDelete: "set null",
      }
    ),

    packageAuditedAt: timestamp("packageAuditedAt", { mode: "date" }),
    packageAuditedById: text("packageAuditedById").references(() => DBUser.id, {
      onDelete: "set null",
    }),

    packageProcessedAt: timestamp("packageProcessedAt", { mode: "date" }),
    packageProcessedById: text("packageProcessedById").references(
      () => DBUser.id,
      {
        onDelete: "set null",
      }
    ),

    auctionSoldAt: timestamp("soldAt", { mode: "date" }),
    auctionSoldById: text("soldById").references(() => DBUser.id, {
      onDelete: "set null",
    }),

    auctionNotSoldAt: timestamp("notSoldAt", { mode: "date" }),
    auctionNotSoldById: text("notSoldById").references(() => DBUser.id, {
      onDelete: "set null",
    }),

    auctionDate: timestamp("auctionDate", { mode: "date" }),
    auctionUrl: text("auctionUrl"),

    paidAtById: text("paidAtById").references(() => DBUser.id, {
      onDelete: "set null",
    }),
    paidAt: timestamp("paidAt", { mode: "date" }),
    paidConsignorWith: text("paidConsignorWith"),
    paidConsignorAmount: decimal("paidConsignorAmount", {
      precision: 10,
      scale: 2,
    }),
    paidConsignorCheckNumber: text("paidConsignorCheckNumber"),

    recycledFromId: text("recycledFromId"),
    recycledById: text("recycledById").references(() => DBUser.id, {
      onDelete: "set null",
    }),
    recycledAt: timestamp("recycledAt", { mode: "date" }),
    recycleCount: integer("recycleCount"),

    payoutPercent: decimal("payoutPercent", {
      precision: 10,
      scale: 2,
    }).default("0.9"),

    postAuctionProcessingAt: timestamp("postAuctionProcessingAt", {
      mode: "date",
    }),
    postAuctionProcessingById: text("postAuctionProcessingById").references(
      () => DBUser.id,
      {
        onDelete: "set null",
      }
    ),

    completedAt: timestamp("completedAt", { mode: "date" }),
    completedById: text("completedById").references(() => DBUser.id, {
      onDelete: "set null",
    }),

    createdAt: timestamp("createdAt", { mode: "date" }).notNull().defaultNow(),
    updatedAt: timestamp("updatedAt", { mode: "date" }),
  },
  (consignment) => ({
    salesRepId: index("consignments-salesRepId_idx").on(consignment.salesRepId),
    userId: index("consignments-userId_idx").on(consignment.userId),
    customerId: index("consignments-customerId_idx").on(consignment.customerId),
  })
);

export const DBConsignmentImage = pgTable(
  "consignment_images",
  {
    id: text("id").notNull().primaryKey().$defaultFn(createId),
    consignmentId: text("consignmentId").references(() => DBConsignment.id, {
      onDelete: "cascade",
    }),

    url: text("url").notNull(),
    path: text("path").notNull(),
    alt: text("alt"),
    width: integer("width"),
    height: integer("height"),
    order: integer("order").notNull().default(0),
    meta: json("meta").$type<Record<string, unknown>>(),
    src: text("src"),
    srcError: text("srcError"),
    processed: boolean("processed").notNull().default(false),
    createdAt: timestamp("createdAt", { mode: "date" }).notNull().defaultNow(),
    updatedAt: timestamp("updatedAt", { mode: "date" }),
  },
  (consignmentImage) => ({
    consignmentIdIdx: index("consignment_images-consignmentId_idx").on(
      consignmentImage.consignmentId
    ),
    processedIdx: index("consignment_images-processed_idx").on(
      consignmentImage.processed
    ),
  })
);

export enum ConsignmentItemPaymentStatus {
  Unsold = "unsold",
  AwaitingPayment = "awaiting_payment",
  Paid = "paid",
  Recycled = "recycled",
  UnpaidCustomer = "unpaid_customer",
}

export const DBConsignmentItemPaymentStatus = pgEnum(
  "consignment_item_payment_status",
  [
    ConsignmentItemPaymentStatus.Unsold,
    ConsignmentItemPaymentStatus.AwaitingPayment,
    ConsignmentItemPaymentStatus.Paid,
    ConsignmentItemPaymentStatus.Recycled,
    ConsignmentItemPaymentStatus.UnpaidCustomer,
  ]
);

export const DBConsignmentItem = pgTable(
  "consignment_items",
  {
    id: text("id").notNull().primaryKey().$defaultFn(createId),
    consignmentId: text("consignmentId")
      .references(() => DBConsignment.id, {
        onDelete: "cascade",
      })
      .notNull(),

    title: text("title").notNull(),
    lotNumber: text("lotNumber").notNull(),

    hammerPrice: decimal("hammerPrice", {
      precision: 10,
      scale: 2,
    }).default("0.0"),

    payout: decimal("payout", {
      precision: 10,
      scale: 2,
    }).default("0.0"),

    auctionLink: text("auctionLink"),

    commission: decimal("commission", {
      precision: 10,
      scale: 2,
    }).default("10.0"),

    soldAt: timestamp("soldAt", { mode: "date" }),
    paymentStatus: DBConsignmentItemPaymentStatus("paymentStatus")
      .notNull()
      .default(ConsignmentItemPaymentStatus.AwaitingPayment),

    payoutPercent: decimal("payoutPercent", {
      precision: 10,
      scale: 2,
    }).default("0.9"),

    createdAt: timestamp("createdAt", { mode: "date" }).notNull().defaultNow(),
    updatedAt: timestamp("updatedAt", { mode: "date" }),
  },
  (customer) => ({
    consignmentIdIdx: index("consignment_items-consignmentId_idx").on(
      customer.consignmentId
    ),
  })
);

export const DBConsignmentItemImage = pgTable(
  "consignment_item_images",
  {
    id: text("id").notNull().primaryKey().$defaultFn(createId),
    consignmentItemId: text("consignmentItemId").notNull(),
    url: text("url").notNull(),
    path: text("path").notNull(),
    alt: text("alt"),
    width: integer("width"),
    height: integer("height"),
    order: integer("order").notNull().default(0),
    meta: json("meta").$type<Record<string, string>>(),
    src: text("src"),
    srcError: text("srcError"),
    processed: boolean("processed").notNull().default(false),
    createdAt: timestamp("createdAt", { mode: "date" }).notNull().defaultNow(),
    updatedAt: timestamp("updatedAt", { mode: "date" }),
  },
  (consignmentItemImage) => ({
    consignmentItemIdIdx: index(
      "consignment_item_images-consignmentItemId_idx"
    ).on(consignmentItemImage.consignmentItemId),
    processedIdx: index("consignment_item_images-processed_idx").on(
      consignmentItemImage.processed
    ),
  })
);
