import { createId } from "@paralleldrive/cuid2";
import { pgEnum, pgTable, text, timestamp } from "drizzle-orm/pg-core";

import { DBUser } from "./auth";

export enum WebhookStatus {
  Enabled = "enabled",
  Disabled = "disabled",
  Failed = "failed",
}

export const DBWebhookStatus = pgEnum("webhook_status", [
  WebhookStatus.Enabled,
  WebhookStatus.Disabled,
  WebhookStatus.Failed,
]);

export const DBWebhook = pgTable("webhooks", {
  id: text("id").notNull().primaryKey().$defaultFn(createId),

  userId: text("userId").references(() => DBUser.id, {
    onDelete: "set null",
  }),

  name: text("name").notNull(),
  description: text("description"),
  url: text("url").notNull(),

  events: text("events").array().notNull().$type<string[]>(),

  status: DBWebhookStatus("status").notNull().default(WebhookStatus.Enabled),
  error: text("error"),

  createdAt: timestamp("createdAt", { mode: "date" }).notNull().defaultNow(),
  updatedAt: timestamp("updatedAt", { mode: "date" }),
});
