import { sql } from "drizzle-orm";
import {
  integer,
  pgEnum,
  pgTable,
  primaryKey,
  text,
  timestamp,
} from "drizzle-orm/pg-core";

export enum UserRole {
  Owner = "owner",
  Admin = "admin",
  Sales = "sales",
  Support = "support",
  Consignments = "consignments",
}

export const UserRolesEnum = pgEnum("user_roles", [
  UserRole.Owner,
  UserRole.Admin,
  UserRole.Sales,
  UserRole.Support,
  UserRole.Consignments,
]);

export const DBUser = pgTable("user", {
  id: text("id")
    .notNull()
    .primaryKey()
    .$defaultFn(() => sql`uuid_generate_v4()`),
  name: text("name"),
  email: text("email").notNull(),
  emailVerified: timestamp("emailVerified", { mode: "date" }),
  image: text("image"),
  password: text("password").notNull(),

  ringCentralNumber: text("ringCentralNumber"),

  createdAt: timestamp("createdAt", { mode: "date" }).notNull().defaultNow(),
  updatedAt: timestamp("updatedAt", { mode: "date" }),

  roles: text("roles").array().$type<string[]>(),
  lastLoginAt: timestamp("lastLoginAt", { mode: "date" }),
  lastSeenAt: timestamp("lastSeenAt", { mode: "date" }),
});

export const DBAccount = pgTable(
  "account",
  {
    userId: text("userId")
      .notNull()
      .references(() => DBUser.id, { onDelete: "cascade" }),
    type: text("type").$type<"oauth" | "oidc" | "email">().notNull(),
    provider: text("provider").notNull(),
    providerAccountId: text("providerAccountId").notNull(),
    refresh_token: text("refresh_token"),
    access_token: text("access_token"),
    expires_at: integer("expires_at"),
    token_type: text("token_type"),
    scope: text("scope"),
    id_token: text("id_token"),
    session_state: text("session_state"),
  },
  (account) => ({
    compoundKey: primaryKey({
      columns: [account.provider, account.providerAccountId],
    }),
  })
);

export const DBSession = pgTable("session", {
  sessionToken: text("sessionToken")
    .notNull()
    .primaryKey()
    .$defaultFn(() => sql`uuid_generate_v4()`),
  userId: text("userId")
    .notNull()
    .references(() => DBUser.id, { onDelete: "cascade" }),
  expires: timestamp("expires", { mode: "date" }).notNull(),
});

export const DBVerificationTokens = pgTable(
  "verificationToken",
  {
    identifier: text("identifier").notNull(),
    token: text("token").notNull(),
    expires: timestamp("expires", { mode: "date" }).notNull(),
  },
  (vt) => ({
    compoundKey: primaryKey({ columns: [vt.identifier, vt.token] }),
  })
);
