import { createId } from "@paralleldrive/cuid2";
import {
  boolean,
  index,
  integer,
  pgTable,
  text,
  timestamp,
  uniqueIndex,
} from "drizzle-orm/pg-core";

export const DBCollection = pgTable(
  "collections",
  {
    id: text("id").notNull().primaryKey().$defaultFn(createId),
    slug: text("slug").notNull().unique().$defaultFn(createId),
    featured: boolean("featured").notNull().default(false),
    image: text("coverImage"),

    title: text("title").notNull(),
    description: text("description"),

    href: text("link"),
    productCount: integer("productCount").notNull().default(0),

    createdAt: timestamp("createdAt", { mode: "date" }).notNull().defaultNow(),
    updatedAt: timestamp("updatedAt", { mode: "date" }),
  },
  (collection) => ({
    slugIdx: uniqueIndex("collections-slug_idx").on(collection.slug),
  }),
);

export const DBCollectionProduct = pgTable(
  "collection_products",
  {
    collectionId: text("collectionId").notNull(),
    productId: text("productId").notNull(),
  },
  (collectionProduct) => ({
    collectionIdIdx: index("collection_products-collectionId_idx").on(
      collectionProduct.collectionId,
    ),
    productIdIdx: index("collection_products-productId_idx").on(
      collectionProduct.productId,
    ),
    uniqueIdx: uniqueIndex("collection_products-unique_idx").on(
      collectionProduct.collectionId,
      collectionProduct.productId,
    ),
  }),
);
