import { relations } from "drizzle-orm";

import { DBApiKey } from "./apiKey";
import { DBUser } from "./auth";
import { DBCart, DBCartItem } from "./cart";
import { DBCategory, DBProductCategory } from "./category";
import {
  DBCharityRequest,
  DBCharityRequestImage,
  DBCharityRequestItem,
  DBCharityRequestItemImage,
} from "./charity";
import { DBCollection, DBCollectionProduct } from "./collection";
import { DBCommissionCategory } from "./commissions";
import {
  DBConsignment,
  DBConsignmentImage,
  DBConsignmentItem,
  DBConsignmentItemImage,
} from "./consignment";
import { DBCustomer } from "./customer";
import { DBFile } from "./file";
import { DBFormSubmission } from "./formSubmission";
import {
  DBGoldslayerLead,
  DBGoldslayerLeadImage,
  DBGoldslayerLeadItem,
  DBGoldslayerLeadItemImage,
} from "./goldslayer";
import { DBNote, DBNoteReading } from "./note";
import { DBOrder, DBOrderItem, DBOrderSource } from "./order";
import { DBPayment, DBPaymentMethod } from "./payment";
import {
  DBProduct,
  DBProductImage,
  DBProductView,
  DBProductWishlistItem,
} from "./product";
import { DBWebhook } from "./webhook";

export const webhookRelations = relations(DBWebhook, ({ one }) => ({
  user: one(DBUser, {
    fields: [DBWebhook.userId],
    references: [DBUser.id],
  }),
}));

export const userRelations = relations(DBUser, ({ many }) => ({
  // products: many(DBProduct),
  orders: many(DBOrder),
  productWishlistItems: many(DBProductWishlistItem),
  productViews: many(DBProductView),
  apiKeys: many(DBApiKey),
  webhooks: many(DBWebhook),
  carts: many(DBCart, {
    relationName: "cart_user",
  }),
  notes: many(DBNote),
  noteRelations: many(DBNoteReading),

  reppedCarts: many(DBCart, {
    relationName: "cart_salesRep",
  }),

  reppedConsignments: many(DBConsignment, {
    relationName: "consignment_salesRep",
  }),

  reppedCharityRequests: many(DBCharityRequest, {
    relationName: "charity_request_salesRep",
  }),

  createdConsignments: many(DBConsignment, {
    relationName: "consignment_user",
  }),

  consignmentReviews: many(DBConsignment, {
    relationName: "consignment_reviewedBy",
  }),

  consignmentShippingLabelsCreated: many(DBConsignment, {
    relationName: "consignment_shippingLabelCreatedBy",
  }),

  consignmentAgreementsSent: many(DBConsignment, {
    relationName: "consignment_agreementSentBy",
  }),

  consignmentSignaturesReceived: many(DBConsignment, {
    relationName: "consignment_signatureReceivedBy",
  }),

  consignmentPackagesReceived: many(DBConsignment, {
    relationName: "consignment_packageReceivedBy",
  }),

  consignmentPackagesAudited: many(DBConsignment, {
    relationName: "consignment_packageAuditedBy",
  }),

  consignmentPackagesProcessed: many(DBConsignment, {
    relationName: "consignment_packageProcessedBy",
  }),

  consignmentAuctionsSold: many(DBConsignment, {
    relationName: "consignment_auctionSoldBy",
  }),

  consignmentAuctionsNotSold: many(DBConsignment, {
    relationName: "consignment_auctionNotSoldBy",
  }),

  consignmentAuctionsRecycled: many(DBConsignment, {
    relationName: "consignment_recycledBy",
  }),

  consignmentPaidBy: many(DBConsignment, {
    relationName: "consignment_paidBy",
  }),

  charityRequestApprovedBy: many(DBCharityRequest, {
    relationName: "charity_request_approvedBy",
  }),

  charityRequestRejectedBy: many(DBCharityRequest, {
    relationName: "charity_request_rejectedBy",
  }),

  uploadedFiles: many(DBFile, {
    relationName: "file_uploadedBy",
  }),
}));

export const productWishlistItemRelations = relations(
  DBProductWishlistItem,
  ({ one }) => ({
    product: one(DBProduct, {
      fields: [DBProductWishlistItem.productId],
      references: [DBProduct.id],
    }),
    user: one(DBUser, {
      fields: [DBProductWishlistItem.userId],
      references: [DBUser.id],
    }),
  })
);

export const apiKeyRelations = relations(DBApiKey, ({ one }) => ({
  user: one(DBUser, {
    fields: [DBApiKey.userId],
    references: [DBUser.id],
  }),
}));

export const cartsRelations = relations(DBCart, ({ many, one }) => ({
  items: many(DBCartItem),
  user: one(DBUser, {
    fields: [DBCart.userId],
    references: [DBUser.id],
    relationName: "cart_user",
  }),
  salesRep: one(DBUser, {
    fields: [DBCart.salesRepId],
    references: [DBUser.id],
    relationName: "cart_salesRep",
  }),
}));

export const cartItemsRelations = relations(DBCartItem, ({ one }) => ({
  cart: one(DBCart, { fields: [DBCartItem.cartId], references: [DBCart.id] }),
  product: one(DBProduct, {
    fields: [DBCartItem.productId],
    references: [DBProduct.id],
  }),
}));

export const categoriesRelations = relations(DBCategory, ({ one, many }) => ({
  parent: one(DBCategory, {
    fields: [DBCategory.parentId],
    references: [DBCategory.id],
    relationName: "children",
  }),
  children: many(DBCategory, {
    relationName: "children",
  }),
  categoryProducts: many(DBProductCategory),
}));

export const collectionsRelations = relations(DBCollection, ({ many }) => ({
  collectionProducts: many(DBCollectionProduct),
}));

export const collectionProductsRelations = relations(
  DBCollectionProduct,
  ({ one }) => ({
    collection: one(DBCollection, {
      fields: [DBCollectionProduct.collectionId],
      references: [DBCollection.id],
    }),
    product: one(DBProduct, {
      fields: [DBCollectionProduct.productId],
      references: [DBProduct.id],
    }),
  })
);

export const customersRelations = relations(DBCustomer, ({ one, many }) => ({
  user: one(DBUser, {
    fields: [DBCustomer.userId],
    references: [DBUser.id],
  }),
  // carts: many(DBCart),
  orders: many(DBOrder),
  paymentMethods: many(DBPaymentMethod),
  consignments: many(DBConsignment, {
    relationName: "consignment_customer",
  }),
}));

export const orderSourcesRelations = relations(DBOrderSource, ({ many }) => ({
  orders: many(DBOrder),
}));

export const ordersRelations = relations(DBOrder, ({ one, many }) => ({
  items: many(DBOrderItem),
  payments: many(DBPayment),

  customer: one(DBCustomer, {
    fields: [DBOrder.customerId],
    references: [DBCustomer.id],
  }),

  source: one(DBOrderSource, {
    fields: [DBOrder.sourceId],
    references: [DBOrderSource.id],
  }),

  salesRep: one(DBUser, {
    fields: [DBOrder.salesRepId],
    references: [DBUser.id],
  }),

  paymentMethod: one(DBPaymentMethod, {
    fields: [DBOrder.paymentMethodId],
    references: [DBPaymentMethod.id],
  }),
}));

export const orderItemsRelations = relations(DBOrderItem, ({ one }) => ({
  order: one(DBOrder, {
    fields: [DBOrderItem.orderId],
    references: [DBOrder.id],
  }),
  product: one(DBProduct, {
    fields: [DBOrderItem.productId],
    references: [DBProduct.id],
  }),
}));

export const paymentMethodsRelations = relations(
  DBPaymentMethod,
  ({ one }) => ({
    user: one(DBUser, {
      fields: [DBPaymentMethod.userId],
      references: [DBUser.id],
    }),
    customer: one(DBCustomer, {
      fields: [DBPaymentMethod.customerId],
      references: [DBCustomer.id],
    }),
  })
);

export const paymentsRelations = relations(DBPayment, ({ one }) => ({
  user: one(DBUser, {
    fields: [DBPayment.userId],
    references: [DBUser.id],
  }),
  customer: one(DBCustomer, {
    fields: [DBPayment.customerId],
    references: [DBCustomer.id],
  }),
  paymentMethod: one(DBPaymentMethod, {
    fields: [DBPayment.paymentMethodId],
    references: [DBPaymentMethod.id],
  }),
  order: one(DBOrder, {
    fields: [DBPayment.orderId],
    references: [DBOrder.id],
  }),
}));

export const categoryProductsRelations = relations(
  DBProductCategory,
  ({ one }) => ({
    product: one(DBProduct, {
      fields: [DBProductCategory.productId],
      references: [DBProduct.id],
    }),
    category: one(DBCategory, {
      fields: [DBProductCategory.categoryId],
      references: [DBCategory.id],
    }),
  })
);

export const commissionCategoryRelations = relations(
  DBCommissionCategory,
  ({ many }) => ({
    products: many(DBProduct),
  })
);

export const productsRelations = relations(DBProduct, ({ many, one }) => ({
  categoryProducts: many(DBProductCategory),
  collectionProducts: many(DBCollectionProduct),
  cartItems: many(DBCartItem),
  images: many(DBProductImage),
  items: many(DBOrderItem),
  views: many(DBProductView),
  orderItems: many(DBOrderItem),

  commissionCategory: one(DBCommissionCategory, {
    fields: [DBProduct.commissionCategoryId],
    references: [DBCommissionCategory.id],
  }),
}));

export const productImagesRelations = relations(DBProductImage, ({ one }) => ({
  product: one(DBProduct, {
    fields: [DBProductImage.productId],
    references: [DBProduct.id],
  }),
}));

export const productViewsRelations = relations(DBProductView, ({ one }) => ({
  product: one(DBProduct, {
    fields: [DBProductView.productId],
    references: [DBProduct.id],
  }),

  user: one(DBUser, {
    fields: [DBProductView.userId],
    references: [DBUser.id],
  }),
}));

export const productWishlistItemsRelations = relations(
  DBProductWishlistItem,
  ({ one }) => ({
    product: one(DBProduct, {
      fields: [DBProductWishlistItem.productId],
      references: [DBProduct.id],
    }),
  })
);

export const formSubmissionRelations = relations(
  DBFormSubmission,
  ({ one }) => ({
    user: one(DBUser, {
      fields: [DBFormSubmission.userId],
      references: [DBUser.id],
    }),
  })
);

export const noteRelations = relations(DBNote, ({ one, many }) => ({
  user: one(DBUser, {
    fields: [DBNote.userId],
    references: [DBUser.id],
  }),

  readings: many(DBNoteReading),
}));

export const noteReadingRelations = relations(DBNoteReading, ({ one }) => ({
  user: one(DBUser, {
    fields: [DBNoteReading.userId],
    references: [DBUser.id],
  }),
  note: one(DBNote, {
    fields: [DBNoteReading.noteId],
    references: [DBNote.id],
  }),
}));

// consignment
export const consignmentRelations = relations(
  DBConsignment,
  ({ many, one }) => ({
    items: many(DBConsignmentItem),
    images: many(DBConsignmentImage),
    uploadedFiles: many(DBFile, {
      relationName: "consignment_uploadedFiles",
    }),

    salesRep: one(DBUser, {
      fields: [DBConsignment.salesRepId],
      references: [DBUser.id],
      relationName: "consignment_salesRep",
    }),

    customer: one(DBCustomer, {
      fields: [DBConsignment.customerId],
      references: [DBCustomer.id],
      relationName: "consignment_customer",
    }),

    user: one(DBUser, {
      fields: [DBConsignment.userId],
      references: [DBUser.id],
      relationName: "consignment_user",
    }),

    reviewedBy: one(DBUser, {
      fields: [DBConsignment.reviewedById],
      references: [DBUser.id],
      relationName: "consignment_reviewedBy",
    }),

    shippingLabelCreatedBy: one(DBUser, {
      fields: [DBConsignment.shippingLabelCreatedById],
      references: [DBUser.id],
      relationName: "consignment_shippingLabelCreatedBy",
    }),

    agreementSentBy: one(DBUser, {
      fields: [DBConsignment.agreementSentById],
      references: [DBUser.id],
      relationName: "consignment_agreementSentBy",
    }),

    signatureReceivedBy: one(DBUser, {
      fields: [DBConsignment.signatureReceivedById],
      references: [DBUser.id],
      relationName: "consignment_signatureReceivedBy",
    }),

    packageReceivedBy: one(DBUser, {
      fields: [DBConsignment.packageReceivedById],
      references: [DBUser.id],
      relationName: "consignment_packageReceivedBy",
    }),

    packageAuditedBy: one(DBUser, {
      fields: [DBConsignment.packageAuditedById],
      references: [DBUser.id],
      relationName: "consignment_packageAuditedBy",
    }),

    packageProcessedBy: one(DBUser, {
      fields: [DBConsignment.packageProcessedById],
      references: [DBUser.id],
      relationName: "consignment_packageProcessedBy",
    }),

    auctionSoldBy: one(DBUser, {
      fields: [DBConsignment.auctionSoldById],
      references: [DBUser.id],
      relationName: "consignment_auctionSoldBy",
    }),

    auctionNotSoldBy: one(DBUser, {
      fields: [DBConsignment.auctionNotSoldById],
      references: [DBUser.id],
      relationName: "consignment_auctionNotSoldBy",
    }),

    paidAtBy: one(DBUser, {
      fields: [DBConsignment.paidAtById],
      references: [DBUser.id],
      relationName: "consignment_paidBy",
    }),

    recycledFrom: one(DBConsignment, {
      fields: [DBConsignment.recycledFromId],
      references: [DBConsignment.id],
      relationName: "consignment_recycledFrom",
    }),

    recycledBy: one(DBUser, {
      fields: [DBConsignment.recycledById],
      references: [DBUser.id],
      relationName: "consignment_recycledBy",
    }),

    postAuctionProcessingBy: one(DBUser, {
      fields: [DBConsignment.postAuctionProcessingById],
      references: [DBUser.id],
      relationName: "consignment_postAuctionProcessingBy",
    }),
  })
);

export const consignmentImagesRelations = relations(
  DBConsignmentImage,
  ({ one }) => ({
    consignment: one(DBConsignment, {
      fields: [DBConsignmentImage.consignmentId],
      references: [DBConsignment.id],
    }),
  })
);

export const consignmentItemsRelations = relations(
  DBConsignmentItem,
  ({ one }) => ({
    consignment: one(DBConsignment, {
      fields: [DBConsignmentItem.consignmentId],
      references: [DBConsignment.id],
    }),
  })
);

export const consignmentItemImagesRelations = relations(
  DBConsignmentItemImage,
  ({ one }) => ({
    consignmentItem: one(DBConsignmentItem, {
      fields: [DBConsignmentItemImage.consignmentItemId],
      references: [DBConsignmentItem.id],
    }),
  })
);

export const charityRequestRelations = relations(
  DBCharityRequest,
  ({ one, many }) => ({
    salesRep: one(DBUser, {
      fields: [DBCharityRequest.salesRepId],
      references: [DBUser.id],
      relationName: "charity_request_salesRep",
    }),

    approvedBy: one(DBUser, {
      fields: [DBCharityRequest.approvedById],
      references: [DBUser.id],
      relationName: "charity_request_approvedBy",
    }),

    rejectedBy: one(DBUser, {
      fields: [DBCharityRequest.rejectedById],
      references: [DBUser.id],
      relationName: "charity_request_rejectedBy",
    }),

    images: many(DBCharityRequestImage),
    items: many(DBCharityRequestItem),

    shippingLabelCreatedBy: one(DBUser, {
      fields: [DBCharityRequest.shippingLabelCreatedById],
      references: [DBUser.id],
      relationName: "charity_request_shippingLabelCreatedBy",
    }),

    packageReceivedBy: one(DBUser, {
      fields: [DBCharityRequest.packageReceivedById],
      references: [DBUser.id],
      relationName: "charity_request_packageReceivedBy",
    }),

    packageAuditedBy: one(DBUser, {
      fields: [DBCharityRequest.packageAuditedById],
      references: [DBUser.id],
      relationName: "charity_request_packageAuditedBy",
    }),

    packageProcessedBy: one(DBUser, {
      fields: [DBCharityRequest.packageProcessedById],
      references: [DBUser.id],
      relationName: "charity_request_packageProcessedBy",
    }),

    offerSentBy: one(DBUser, {
      fields: [DBCharityRequest.offerSentById],
      references: [DBUser.id],
      relationName: "charity_request_offerSentBy",
    }),

    offerAcceptedBy: one(DBUser, {
      fields: [DBCharityRequest.offerAcceptedById],
      references: [DBUser.id],
      relationName: "charity_request_offerAcceptedBy",
    }),

    offerRejectedBy: one(DBUser, {
      fields: [DBCharityRequest.offerRejectedById],
      references: [DBUser.id],
      relationName: "charity_request_offerRejectedBy",
    }),

    paidBy: one(DBUser, {
      fields: [DBCharityRequest.paidById],
      references: [DBUser.id],
      relationName: "charity_request_paidBy",
    }),
  })
);

export const charityRequestImagesRelations = relations(
  DBCharityRequestImage,
  ({ one }) => ({
    charityRequest: one(DBCharityRequest, {
      fields: [DBCharityRequestImage.charityRequestId],
      references: [DBCharityRequest.id],
    }),
  })
);

export const charityRequestItemsRelations = relations(
  DBCharityRequestItem,
  ({ one }) => ({
    charityRequest: one(DBCharityRequest, {
      fields: [DBCharityRequestItem.charityRequestId],
      references: [DBCharityRequest.id],
    }),
  })
);

export const charityRequestItemImagesRelations = relations(
  DBCharityRequestItemImage,
  ({ one }) => ({
    charityRequestItem: one(DBCharityRequestItem, {
      fields: [DBCharityRequestItemImage.charityRequestItemId],
      references: [DBCharityRequestItem.id],
    }),
  })
);

export const goldslayerLeadRelations = relations(
  DBGoldslayerLead,
  ({ one, many }) => ({
    salesRep: one(DBUser, {
      fields: [DBGoldslayerLead.salesRepId],
      references: [DBUser.id],
      relationName: "goldslayer_lead_salesRep",
    }),

    approvedBy: one(DBUser, {
      fields: [DBGoldslayerLead.approvedById],
      references: [DBUser.id],
      relationName: "goldslayer_lead_approvedBy",
    }),

    rejectedBy: one(DBUser, {
      fields: [DBGoldslayerLead.rejectedById],
      references: [DBUser.id],
      relationName: "goldslayer_lead_rejectedBy",
    }),

    images: many(DBGoldslayerLeadImage),
    items: many(DBGoldslayerLeadItem),
    // itemsImages: many(DBGoldslayerLeadItemImage),

    shippingLabelCreatedBy: one(DBUser, {
      fields: [DBGoldslayerLead.shippingLabelCreatedById],
      references: [DBUser.id],
      relationName: "goldslayer_lead_shippingLabelCreatedBy",
    }),

    agreementSentBy: one(DBUser, {
      fields: [DBGoldslayerLead.agreementSentById],
      references: [DBUser.id],
      relationName: "goldslayer_lead_agreementSentBy",
    }),

    signatureReceivedBy: one(DBUser, {
      fields: [DBGoldslayerLead.signatureReceivedById],
      references: [DBUser.id],
      relationName: "goldslayer_lead_signatureReceivedBy",
    }),

    packageReceivedBy: one(DBUser, {
      fields: [DBGoldslayerLead.packageReceivedById],
      references: [DBUser.id],
      relationName: "goldslayer_lead_packageReceivedBy",
    }),

    packageAuditedBy: one(DBUser, {
      fields: [DBGoldslayerLead.packageAuditedById],
      references: [DBUser.id],
      relationName: "goldslayer_lead_packageAuditedBy",
    }),

    packageProcessedBy: one(DBUser, {
      fields: [DBGoldslayerLead.packageProcessedById],
      references: [DBUser.id],
      relationName: "goldslayer_lead_packageProcessedBy",
    }),

    offerSentBy: one(DBUser, {
      fields: [DBGoldslayerLead.offerSentById],
      references: [DBUser.id],
      relationName: "goldslayer_lead_offerSentBy",
    }),

    offerAcceptedBy: one(DBUser, {
      fields: [DBGoldslayerLead.offerAcceptedById],
      references: [DBUser.id],
      relationName: "goldslayer_lead_offerAcceptedBy",
    }),

    offerRejectedBy: one(DBUser, {
      fields: [DBGoldslayerLead.offerRejectedById],
      references: [DBUser.id],
      relationName: "goldslayer_lead_offerRejectedBy",
    }),

    paidBy: one(DBUser, {
      fields: [DBGoldslayerLead.paidById],
      references: [DBUser.id],
      relationName: "goldslayer_lead_paidBy",
    }),
  })
);

export const goldslayerLeadImagesRelations = relations(
  DBGoldslayerLeadImage,
  ({ one }) => ({
    goldslayerLead: one(DBGoldslayerLead, {
      fields: [DBGoldslayerLeadImage.goldslayerLeadId],
      references: [DBGoldslayerLead.id],
    }),
  })
);

export const goldslayerLeadItemsRelations = relations(
  DBGoldslayerLeadItem,
  ({ one }) => ({
    goldslayerLead: one(DBGoldslayerLead, {
      fields: [DBGoldslayerLeadItem.goldslayerLeadId],
      references: [DBGoldslayerLead.id],
    }),
  })
);

export const goldslayerLeadItemImagesRelations = relations(
  DBGoldslayerLeadItemImage,
  ({ one }) => ({
    goldslayerLeadItem: one(DBGoldslayerLeadItem, {
      fields: [DBGoldslayerLeadItemImage.goldslayerLeadItemId],
      references: [DBGoldslayerLeadItem.id],
    }),
  })
);
