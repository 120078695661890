import { createId } from "@paralleldrive/cuid2";
import {
  decimal,
  pgTable,
  text,
  timestamp,
  uniqueIndex,
} from "drizzle-orm/pg-core";

export const DBCommissionCategory = pgTable(
  "commission_categories",
  {
    id: text("id").notNull().primaryKey().$defaultFn(createId),

    name: text("name").notNull(),
    percent: decimal("percent", {
      precision: 10,
      scale: 2,
    }).notNull(),

    createdAt: timestamp("createdAt", { mode: "date" }).notNull().defaultNow(),
    updatedAt: timestamp("updatedAt", { mode: "date" }),
  },
  (category) => ({
    uniqueNameIdx: uniqueIndex("commission_categories-name_unique").on(
      category.name,
    ),
  }),
);
