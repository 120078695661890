import { createId } from "@paralleldrive/cuid2";
import {
  index,
  integer,
  pgTable,
  text,
  timestamp,
  uniqueIndex,
} from "drizzle-orm/pg-core";

export const DBCategory = pgTable(
  "categories",
  {
    id: text("id").notNull().primaryKey().$defaultFn(createId),
    title: text("title", {}).notNull(),
    slug: text("slug").notNull().unique(),
    parentId: text("parentId"),
    image: text("coverImage"),
    productCount: integer("productCount").notNull().default(0),
    createdAt: timestamp("createdAt", { mode: "date" }).notNull().defaultNow(),
    updatedAt: timestamp("updatedAt", { mode: "date" }),
  },
  (category) => ({
    slugIndex: index("categories-slug_idx").on(category.slug),
    parentIdIndex: index("categories-parentId_idx").on(category.parentId),
  }),
);

export const DBProductCategory = pgTable(
  "product_categories",
  {
    productId: text("productId").notNull(),
    categoryId: text("categoryId").notNull(),
    createdAt: timestamp("createdAt", { mode: "date" }).notNull().defaultNow(),
  },
  (productCategory) => ({
    productIdIdx: index("product_categories-productId_idx").on(
      productCategory.productId,
    ),
    categoryIdIdx: index("product_categories-categoryId_idx").on(
      productCategory.categoryId,
    ),
    uniqueIdx: uniqueIndex("product_categories-unique_idx").on(
      productCategory.productId,
      productCategory.categoryId,
    ),
  }),
);
