import { createId } from "@paralleldrive/cuid2";
import {
  decimal,
  index,
  pgEnum,
  pgTable,
  text,
  timestamp,
} from "drizzle-orm/pg-core";

export enum MetalPriceSource {
  Manual = "manual",
  MetalPriceApi = "metalpriceapi",
}
export const DBMetalPriceSource = pgEnum("store_metal_price_source", [
  MetalPriceSource.Manual,
  MetalPriceSource.MetalPriceApi,
]);

export const DBMetalPrice = pgTable(
  "metal_prices",
  {
    id: text("id").notNull().primaryKey().$defaultFn(createId),
    timestamp: timestamp("timestamp", { mode: "date" }).notNull(),
    source: DBMetalPriceSource("source").notNull(),

    ticker: text("ticker").notNull(),
    price: decimal("price", {
      precision: 10,
      scale: 2,
    }).notNull(),

    createdAt: timestamp("createdAt", { mode: "date" }).notNull().defaultNow(),
  },
  (metalPrice) => ({
    timestampIdx: index("metal_prices-timestamp_idx").on(metalPrice.timestamp),
    sourceIdx: index("metal_prices-source_idx").on(metalPrice.source),
    tickerIdx: index("metal_prices-ticker_idx").on(metalPrice.ticker),
  }),
);
