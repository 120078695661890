import { createId } from "@paralleldrive/cuid2";
import { jsonb, pgEnum, pgTable, text, timestamp } from "drizzle-orm/pg-core";

import { DBUser } from "./auth";

export enum FormType {
  LookingForSomethingSpecific = "looking_for_something_specific",
  ContactUs = "contact_us",
  ConsignmentForm = "consignment_form",
  SpeakToSalesRep = "speak_to_sales_rep",
  SellToUs = "sell_to_us",
  Newsletter = "newsletter",
  CollectionPreorder = "collection_preorder",
  InvestmentQuestionnaire = "investment_questionnaire",
}

export const DBFormType = pgEnum("form_type", [
  FormType.LookingForSomethingSpecific,
  FormType.ContactUs,
  FormType.ConsignmentForm,
  FormType.SpeakToSalesRep,
  FormType.SellToUs,
  FormType.Newsletter,
  FormType.CollectionPreorder,
  FormType.InvestmentQuestionnaire,
]);

export const DBFormSubmission = pgTable("form_submissions", {
  id: text("id").notNull().primaryKey().$defaultFn(createId),

  userId: text("userId").references(() => DBUser.id, {
    onDelete: "set null",
  }),

  type: DBFormType("type").notNull(),

  data: jsonb("data").notNull().$type<Record<string, any>>(),

  handledBy: text("handledBy").references(() => DBUser.id, {
    onDelete: "set null",
  }),
  handledAt: timestamp("handledAt", { mode: "date" }),

  createdAt: timestamp("createdAt", { mode: "date" }).notNull().defaultNow(),
});
