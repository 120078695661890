import { createId } from "@paralleldrive/cuid2";
import { index, integer, pgTable, text, timestamp } from "drizzle-orm/pg-core";

import { DBUser } from "./auth";
import { DBOrderSource } from "./order";

export const DBCart = pgTable(
  "carts",
  {
    id: text("id").notNull().primaryKey().$defaultFn(createId),

    userId: text("userId").references(() => DBUser.id, { onDelete: "cascade" }),
    sourceId: text("sourceId").references(() => DBOrderSource.id, {
      onDelete: "set null",
    }),
    salesRepId: text("salesRepId").references(() => DBUser.id, {
      onDelete: "set null",
    }),

    abandonedAt: timestamp("abandonedAt", { mode: "date" }),

    createdAt: timestamp("createdAt", { mode: "date" }).notNull().defaultNow(),
    updatedAt: timestamp("updatedAt", { mode: "date" }),
  },
  (cart) => ({
    userIdIdx: index("userId_idx").on(cart.userId),
  }),
);

export const DBCartItem = pgTable(
  "cart_items",
  {
    id: text("id").notNull().primaryKey().$defaultFn(createId),
    cartId: text("cartId").notNull(),
    productId: text("productId").notNull(),
    quantity: integer("quantity").notNull(),
    createdAt: timestamp("createdAt", { mode: "date" }).notNull().defaultNow(),
    updatedAt: timestamp("updatedAt", { mode: "date" }),
  },
  (cartItem) => ({
    cartIdIdx: index("cart_items-cartId_idx").on(cartItem.cartId),
    productIdIdx: index("cart_items-productId_idx").on(cartItem.productId),
  }),
);
