import { createId } from "@paralleldrive/cuid2";
import {
  boolean,
  decimal,
  index,
  integer,
  json,
  pgEnum,
  pgTable,
  serial,
  text,
  timestamp,
} from "drizzle-orm/pg-core";

import { DBUser } from "./auth";

export enum CharityRequestStatus {
  Pending = "pending",
  Approved = "approved",
  Rejected = "rejected",
  PackageReceived = "packageReceived",
  Processing = "processing",
  OfferSent = "offerSent",
  OfferAccepted = "offerAccepted",
  OfferRejected = "offerRejected",
  Cancelled = "cancelled",
  Paid = "paid",
}

export const CharityRequestStatusEnum = pgEnum("charity_request_status", [
  CharityRequestStatus.Pending,
  CharityRequestStatus.Approved,
  CharityRequestStatus.Rejected,
  CharityRequestStatus.OfferSent,
  CharityRequestStatus.OfferAccepted,
  CharityRequestStatus.OfferRejected,
  CharityRequestStatus.PackageReceived,
  CharityRequestStatus.Processing,
  CharityRequestStatus.Cancelled,
  CharityRequestStatus.Paid,
]);

export enum CharityRequestPercents {
  Ten = "10%",
  Fifty = "50%",
  OneHundred = "100%",
}

export const CharityRequestPercentsEnum = pgEnum("charity_request_percents", [
  CharityRequestPercents.Ten,
  CharityRequestPercents.Fifty,
  CharityRequestPercents.OneHundred,
]);

export enum CharityRequestPaymentType {
  Check = "check",
  ECheck = "eCheck",
}

export const CharityRequestPaymentTypeEnum = pgEnum(
  "charity_request_payment_type",
  [CharityRequestPaymentType.Check, CharityRequestPaymentType.ECheck]
);

export const DBCharityRequest = pgTable("charity_requests", {
  id: text("id").notNull().primaryKey().$defaultFn(createId),

  charitySlug: text("charitySlug").notNull(),

  salesRepId: text("salesRepId").references(() => DBUser.id, {
    onDelete: "set null",
  }),

  number: serial("number"),

  name: text("name").notNull(),
  email: text("email").notNull(),
  phone: text("phone").notNull(),
  address1: text("address1").notNull(),
  address2: text("address2"),
  city: text("city").notNull(),
  state: text("state").notNull(),
  zip: text("zip").notNull(),
  country: text("country").notNull(),

  donationPercent: CharityRequestPercentsEnum("donationPercent").notNull(),
  items: text("items").array().notNull().$type<string[]>(),
  verifiedContents: text("verifiedContents"),
  paymentType: CharityRequestPaymentTypeEnum("paymentType").notNull(),

  shippingCarrier: text("shippingCarrier"),
  shippingPackage: text("shippingPackage"),
  shippingService: text("shippingService"),
  shippingWeightUnits: text("shippingWeightUnits"),
  shippingWeightValue: decimal("shippingWeightValue", {
    precision: 10,
    scale: 2,
  }),
  shippingDate: timestamp("shippingDate", { mode: "date" }),
  shippingLabel: text("shippingLabel"),
  shippingId: text("shippingId"),
  shippingTrackingNumber: text("shippingTrackingNumber"),

  status: CharityRequestStatusEnum("status")
    .notNull()
    .default(CharityRequestStatus.Pending),
  approvedAt: timestamp("approvedAt", { mode: "date" }),
  approvedById: text("approvedById").references(() => DBUser.id, {
    onDelete: "set null",
  }),
  rejectedAt: timestamp("rejectedAt", { mode: "date" }),
  rejectedById: text("rejectedById").references(() => DBUser.id, {
    onDelete: "set null",
  }),
  rejectionReason: text("rejectionReason"),
  paidWith: text("paidWith"),
  paidAmount: decimal("paidAmount", { precision: 10, scale: 2 }),
  paidCheckNumber: text("paidCheckNumber"),

  shippingLabelCreatedAt: timestamp("shippingLabelCreatedAt", { mode: "date" }),
  shippingLabelCreatedById: text("shippingLabelCreatedById").references(
    () => DBUser.id,
    {
      onDelete: "set null",
    }
  ),

  packageReceivedAt: timestamp("packageReceivedAt", { mode: "date" }),
  packageReceivedById: text("packageReceivedById").references(() => DBUser.id, {
    onDelete: "set null",
  }),

  packageAuditedAt: timestamp("packageAuditedAt", { mode: "date" }),
  packageAuditedById: text("packageAuditedById").references(() => DBUser.id, {
    onDelete: "set null",
  }),

  packageProcessedAt: timestamp("packageProcessedAt", { mode: "date" }),
  packageProcessedById: text("packageProcessedById").references(
    () => DBUser.id,
    {
      onDelete: "set null",
    }
  ),

  offerAmount: decimal("offerAmount", { precision: 10, scale: 2 }),
  offerSentAt: timestamp("offerSentAt", { mode: "date" }),
  offerSentById: text("offerSentById").references(() => DBUser.id, {
    onDelete: "set null",
  }),

  offerAcceptedAt: timestamp("offerAcceptedAt", { mode: "date" }),
  offerAcceptedById: text("offerAcceptedById").references(() => DBUser.id, {
    onDelete: "set null",
  }),

  offerRejectedAt: timestamp("offerRejectedAt", { mode: "date" }),
  offerRejectedById: text("offerRejectedById").references(() => DBUser.id, {
    onDelete: "set null",
  }),

  paidAt: timestamp("paidAt", { mode: "date" }),
  paidById: text("paidById").references(() => DBUser.id, {
    onDelete: "set null",
  }),

  createdAt: timestamp("createdAt", { mode: "date" }).notNull().defaultNow(),
  updatedAt: timestamp("updatedAt", { mode: "date" }),
});

export const DBCharityRequestImage = pgTable(
  "charity_request_images",
  {
    id: text("id").notNull().primaryKey().$defaultFn(createId),
    charityRequestId: text("charityRequestId").references(
      () => DBCharityRequest.id,
      {
        onDelete: "cascade",
      }
    ),

    url: text("url").notNull(),
    path: text("path").notNull(),
    alt: text("alt"),
    width: integer("width"),
    height: integer("height"),
    order: integer("order").notNull().default(0),
    meta: json("meta").$type<Record<string, string>>(),
    src: text("src"),
    srcError: text("srcError"),
    processed: boolean("processed").notNull().default(false),
    createdAt: timestamp("createdAt", { mode: "date" }).notNull().defaultNow(),
    updatedAt: timestamp("updatedAt", { mode: "date" }),
  },
  (charityRequestImage) => ({
    charityRequestIdIdx: index(
      "charity_request_images-charityRequestId_idx"
    ).on(charityRequestImage.charityRequestId),
    processedIdx: index("charity_request_images-processed_idx").on(
      charityRequestImage.processed
    ),
  })
);

export const DBCharityRequestItem = pgTable(
  "charity_request_items",
  {
    id: text("id").notNull().primaryKey().$defaultFn(createId),
    charityRequestId: text("charityRequestId")
      .references(() => DBCharityRequest.id, {
        onDelete: "cascade",
      })
      .notNull(),

    title: text("title").notNull(),
    lotNumber: text("lotNumber").notNull(),

    hammerPrice: decimal("hammerPrice", {
      precision: 10,
      scale: 2,
    }).default("0.0"),

    payout: decimal("payout", {
      precision: 10,
      scale: 2,
    }).default("0.0"),

    auctionLink: text("auctionLink"),

    commission: decimal("commission", {
      precision: 10,
      scale: 2,
    }).default("10.0"),

    soldAt: timestamp("soldAt", { mode: "date" }),

    createdAt: timestamp("createdAt", { mode: "date" }).notNull().defaultNow(),
    updatedAt: timestamp("updatedAt", { mode: "date" }),
  },
  (charityRequestItem) => ({
    charityRequestIdIdx: index("charity_request_items-charityRequestId_idx").on(
      charityRequestItem.charityRequestId
    ),
  })
);

export const DBCharityRequestItemImage = pgTable(
  "charity_request_item_images",
  {
    id: text("id").notNull().primaryKey().$defaultFn(createId),
    charityRequestItemId: text("charityRequestItemId").notNull(),
    url: text("url").notNull(),
    path: text("path").notNull(),
    alt: text("alt"),
    width: integer("width"),
    height: integer("height"),
    order: integer("order").notNull().default(0),
    meta: json("meta").$type<Record<string, string>>(),
    src: text("src"),
    srcError: text("srcError"),
    processed: boolean("processed").notNull().default(false),
    createdAt: timestamp("createdAt", { mode: "date" }).notNull().defaultNow(),
    updatedAt: timestamp("updatedAt", { mode: "date" }),
  },
  (consignmentItemImage) => ({
    charityRequestItemIdIdx: index(
      "charity_request_item_images-charityRequestItemId_idx"
    ).on(consignmentItemImage.charityRequestItemId),
    processedIdx: index("charity_request_item_images-processed_idx").on(
      consignmentItemImage.processed
    ),
  })
);
