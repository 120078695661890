import { createId } from "@paralleldrive/cuid2";
import {
  index,
  pgEnum,
  pgTable,
  text,
  timestamp,
  uniqueIndex,
} from "drizzle-orm/pg-core";

import { DBUser } from "./auth";

export enum NoteableType {
  Customer = "customer",
  Order = "order",
  Product = "product",
  Consignment = "consignment",
  CharityRequest = "charity_request",
  GoldslayerLead = "goldslayer_lead",
}

export const DBNoteableType = pgEnum("noteable_type", [
  NoteableType.Customer,
  NoteableType.Order,
  NoteableType.Product,
  NoteableType.Consignment,
  NoteableType.CharityRequest,
  NoteableType.GoldslayerLead,
]);

export const DBNote = pgTable(
  "notes",
  {
    id: text("id").notNull().primaryKey().$defaultFn(createId),

    notableType: DBNoteableType("notableType").notNull(),
    notableId: text("notableId").notNull(),

    userId: text("userId").references(() => DBUser.id, {
      onDelete: "cascade",
    }),

    note: text("note").notNull(),

    createdAt: timestamp("createdAt", { mode: "date" }).notNull().defaultNow(),
    updatedAt: timestamp("updatedAt", { mode: "date" }),
  },
  (note) => ({
    notableTypeIdx: index("notes-notableType_idx").on(note.notableType),
    notableIdIdx: index("notes-notableId_idx").on(note.notableId),
  }),
);

export const DBNoteReading = pgTable(
  "note_readings",
  {
    id: text("id").notNull().primaryKey().$defaultFn(createId),

    userId: text("userId").references(() => DBUser.id, {
      onDelete: "cascade",
    }),

    noteId: text("noteId").references(() => DBNote.id, {
      onDelete: "cascade",
    }),

    createdAt: timestamp("createdAt", { mode: "date" }).notNull().defaultNow(),
  },
  (note) => ({
    noteIdIdx: uniqueIndex("note_readings-noteId_idx").on(note.noteId),
  }),
);
