import { createId } from "@paralleldrive/cuid2";
import {
  index,
  integer,
  jsonb,
  pgEnum,
  pgTable,
  text,
  timestamp,
} from "drizzle-orm/pg-core";

import { DBUser } from "./auth";

export enum FileableType {
  Consignment = "consignment",
}

export const FileableTypes = pgEnum("fileableTypes", [
  FileableType.Consignment,
]);

export const DBFile = pgTable(
  "files",
  {
    id: text("id").notNull().primaryKey().$defaultFn(createId),

    fileableId: text("fileableId"),
    fileableType: FileableTypes("fileableType").default(
      FileableType.Consignment
    ),

    uploadedById: text("uploadedById").references(() => DBUser.id, {
      onDelete: "cascade",
    }),

    contentType: text("contentType").notNull(),
    contentSize: integer("contentSize").notNull(),

    path: text("path").notNull(),
    alt: text("alt"),
    filename: text("filename").notNull(),
    description: text("description"),
    order: integer("order").notNull().default(0),
    meta: jsonb("meta").default({}).$type<Record<string, any>>(),

    createdAt: timestamp("createdAt", { mode: "date" }).notNull().defaultNow(),
    updatedAt: timestamp("updatedAt", { mode: "date" }),
  },
  (file) => ({
    uploadedByIdIdx: index("files-uploadedById_idx").on(file.uploadedById),
  })
);
