import { createId } from "@paralleldrive/cuid2";
import {
  boolean,
  doublePrecision,
  index,
  integer,
  jsonb,
  pgEnum,
  pgTable,
  text,
  timestamp,
  uniqueIndex,
} from "drizzle-orm/pg-core";

import { DBUser } from "./auth";
import { DBCommissionCategory } from "./commissions";

export enum ProductStatus {
  Draft = "draft",
  Published = "published",
  Deleted = "deleted",
  SourceDeleted = "source-deleted",
}

export const DBProductStatus = pgEnum(
  "undefined-undefined-store_product_status",
  [
    ProductStatus.Draft,
    ProductStatus.Published,
    ProductStatus.Deleted,
    ProductStatus.SourceDeleted,
  ]
);

export enum ProductInventoryLevel {
  InStock = "in_stock",
  OutOfStock = "out_of_stock",
}
export const DBProductInventoryLevel = pgEnum(
  "undefined-undefined-store_product_inventory_level",
  [ProductInventoryLevel.InStock, ProductInventoryLevel.OutOfStock]
);

export enum ProductSource {
  Manual = "manual",
  GSAInventory = "gsa-inventory",
  GSABullion = "gsa-bullion",
  Heritage = "heritage",
  Fitztrade = "fitztrade",
  StoreImport = "store-import",
  CustomOrder = "custom-order",
}
export const DBProductSource = pgEnum(
  "undefined-undefined-store_product_source",
  [
    ProductSource.Manual,
    ProductSource.GSAInventory,
    ProductSource.GSABullion,
    ProductSource.Heritage,
    ProductSource.Fitztrade,
    ProductSource.StoreImport,
    ProductSource.CustomOrder,
  ]
);

export const DBProduct = pgTable(
  "products",
  {
    id: text("id").notNull().primaryKey().$defaultFn(createId),
    title: text("title").notNull(),
    slug: text("slug").notNull().unique().$defaultFn(createId),
    price: integer("price").notNull(),
    cost: integer("cost"),
    description: text("description"),
    status: DBProductStatus("status").notNull().default(ProductStatus.Draft),
    inventoryLevel: DBProductInventoryLevel("inventoryLevel")
      .notNull()
      .default(ProductInventoryLevel.InStock),
    sku: text("sku").notNull().unique(),
    source: DBProductSource("source").notNull(),
    stockedQuantity: integer("stockedQuantity").notNull(),
    reservedQuantity: integer("reservedQuantity").notNull(),
    meta: jsonb("meta").$type<{
      grader?: string | null | undefined;
      grade?: string | null | undefined;
      year?: string | null | undefined;
      denomination?: string | null | undefined;
      weight?: string | null | undefined;
    }>(),
    viewCount: integer("viewCount").notNull().default(0),
    orderCount: integer("orderCount").notNull().default(0),
    publicNote: text("publicNote"),
    commissionCategoryId: text("commissionCategoryId").references(
      () => DBCommissionCategory.id
    ),
    createdAt: timestamp("createdAt", { mode: "date" }).notNull().defaultNow(),
    updatedAt: timestamp("updatedAt", { mode: "date" }),
  },
  (product) => ({
    slugIndex: index("products-slug_idx").on(product.slug),
    statusIndex: index("products-status_idx").on(product.status),
    sourceIndex: index("products-source_idx").on(product.source),
    skuIdx: uniqueIndex("products-sku_idx").on(product.sku),
  })
);

export const DBProductImage = pgTable(
  "product_images",
  {
    id: text("id").notNull().primaryKey().$defaultFn(createId),
    productId: text("productId").notNull(),
    url: text("url").notNull(),
    path: text("path").notNull(),
    alt: text("alt"),
    featured: boolean("featured").notNull().default(false),
    width: integer("width"),
    height: integer("height"),
    order: integer("order").notNull().default(0),
    meta: jsonb("meta").default({}).$type<Record<string, any>>(),
    src: text("src"),
    srcError: text("srcError"),
    processed: boolean("processed").notNull().default(false),
    createdAt: timestamp("createdAt", { mode: "date" }).notNull().defaultNow(),
    updatedAt: timestamp("updatedAt", { mode: "date" }),
  },
  (productImage) => ({
    productIdIdx: index("product_images-productId_idx").on(
      productImage.productId
    ),
    processedIdx: index("product_images-processed_idx").on(
      productImage.processed
    ),
  })
);

export const DBProductView = pgTable(
  "product_views",
  {
    id: text("id").notNull().primaryKey().$defaultFn(createId),
    userId: text("userId").references(() => DBUser.id, { onDelete: "cascade" }),
    productId: text("productId")
      .references(() => DBProduct.id, { onDelete: "cascade" })
      .notNull(),
    ip: text("ip"),

    country: text("country"),
    countryName: text("countryName"),
    region: text("region"),
    regionName: text("regionName"),
    city: text("city"),
    postalCode: text("postalCode"),
    timeZone: text("timeZone"),
    latitude: doublePrecision("latitude"),
    longitude: doublePrecision("longitude"),
    metroCode: text("metroCode"),

    createdAt: timestamp("createdAt", { mode: "date" }).notNull().defaultNow(),
  },
  (productView) => ({
    userIdIdx: index("product_views-userId_idx").on(productView.userId),
    productIdIdx: index("product_views-productId_idx").on(
      productView.productId
    ),
  })
);

export const DBProductWishlistItem = pgTable(
  "product_wishlist_items",
  {
    userId: text("userId")
      .notNull()
      .references(() => DBUser.id, { onDelete: "cascade" }),
    productId: text("productId")
      .notNull()
      .references(() => DBProduct.id, {
        onDelete: "cascade",
      }),
    createdAt: timestamp("createdAt", { mode: "date" }).notNull().defaultNow(),
  },
  (productWishlistItem) => ({
    userIdIdx: index("product_wishlist_items-userId_idx").on(
      productWishlistItem.userId
    ),
    productIdIdx: index("product_wishlist_items-productId_idx").on(
      productWishlistItem.productId
    ),
    userProductIdx: uniqueIndex("product_wishlist_items-userProduct_idx").on(
      productWishlistItem.userId,
      productWishlistItem.productId
    ),
  })
);
