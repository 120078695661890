import type { VariantProps } from "class-variance-authority";
import * as React from "react";
import { Slot } from "@radix-ui/react-slot";
import { cva } from "class-variance-authority";

import { Icons } from "../../icons";
import { cn } from "../../lib/utils";

const buttonVariants = cva(
  "relative inline-flex items-center justify-center gap-1 whitespace-nowrap rounded-md ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50",
  {
    variants: {
      variant: {
        default: "bg-primary text-primary-foreground hover:bg-primary/90",
        blue: "bg-blue-950 text-white hover:bg-blue-700",
        destructive:
          "bg-destructive text-destructive-foreground hover:bg-destructive/90",
        outline:
          "border border-input bg-background hover:bg-accent hover:text-accent-foreground",
        secondary:
          "bg-secondary text-secondary-foreground hover:bg-secondary/80",
        success: "bg-green-600 text-green-50 hover:bg-green-500",
        ghost: "hover:bg-accent hover:text-accent-foreground",
        link: "text-primary underline-offset-4 hover:underline",
        white: "bg-white text-primary hover:bg-gray-50",
        black: "bg-black text-white hover:bg-gray-900",
        info: "bg-blue-100 text-blue-400 hover:bg-blue-200",
        gold: "bg-[#c29b40] font-medium text-black text-white hover:bg-[#c29b40]/80",
      },
      size: {
        default: "h-10 px-4 py-2  text-sm font-medium",
        xs: "h-6 rounded-md px-2  text-xs font-normal",
        sm: "h-9 rounded-md px-3  text-sm font-medium",
        lg: "text-md h-11 rounded-md  px-8 font-medium",
        xl: "h-12 rounded-md px-10  text-lg font-medium",
        "2xl": "h-12 rounded-md px-12  text-xl font-medium",
        "3xl": "h-12 rounded-md px-16  text-2xl font-medium",
        icon: "h-10 w-10",
      },
    },
    defaultVariants: {
      variant: "default",
      size: "default",
    },
  },
);

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, variant, size, asChild = false, ...props }, ref) => {
    const Comp = asChild ? Slot : "button";
    return (
      <Comp
        className={cn(buttonVariants({ variant, size, className }))}
        ref={ref}
        {...props}
      />
    );
  },
);
Button.displayName = "Button";

interface LoadingButtonProps extends ButtonProps {
  loading: boolean;
}
const LoadingButton = ({ loading, ...props }: LoadingButtonProps) => {
  return (
    <Button {...props}>
      {loading ? <Icons.spinner className="h-4 w-4" /> : props.children}
    </Button>
  );
};
LoadingButton.displayName = "LoadingButton";

export { Button, LoadingButton, buttonVariants };
